<template>
    <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="sendEmailTwo-container">
        <div class="title">发送日报邮件</div>
        <!-- <div class="grey-line"></div>
        <div class="main-title"> <span>发送日报邮件</span><span></span></div> -->
        <div class="step-container">
            <el-steps style="width: 100%" :active="4" align-center>
                <el-step title="选择邮件模板" icon="el-icon-success"></el-step>
                <el-step title="邮件内容配置" icon="el-icon-success"></el-step>
                <el-step title="服务数据预览" icon="el-icon-success"></el-step>
                <el-step title="邮件内容确认"></el-step>
            </el-steps>
        </div>
        <div class="grey-line"></div>
        <div class="sendEmailTwo-main">
            <div class="main-title"> <span>邮件内容确认</span><span></span></div>
            <el-form  id="dowmloadImg" class="form-container">
                <el-form-item>
                    <div class="tip-text">尊敬的各位领导、同事：</div>
                    <div class="tip-text">为了方便第一时间联系到店铺负责人，附以下紧急联系方式：</div>
                    <el-table class="common-table" border :data="ruleForm.infoSetList">
                        <el-table-column prop="name" label="服务对接角色" :formatter="tableColumn"></el-table-column>
                        <el-table-column label="对接名称" :formatter="tableColumn">
                            <template slot-scope="scope">
                                <div v-if="scope.row.name == '项目负责人'">
                                    <span>{{scope.row.projectLeaderName}}</span>
                                </div>
                                <div v-else-if="scope.row.name == '客服经理'">
                                    <span>{{scope.row.serviceManagerName}}</span>
                                </div>
                                <div v-else-if="scope.row.name == '客服组长'">
                                    <span>{{scope.row.teamLeaderName}}</span>
                                </div>
                                <div v-else-if="scope.row.name == '质检人员'">
                                    <span>{{scope.row.qualityInspectionName}}</span>
                                </div>
                                <div v-else-if="scope.row.name == '对接群'">
                                    <span>{{scope.row.grop}}</span>
                                </div>
                                <div v-else>--</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="联系方式" :formatter="tableColumn">
                            <template slot-scope="scope">
                                <div v-if="scope.row.name == '项目负责人'">
                                    <span>{{scope.row.projectLeaderTel}}</span>
                                </div>
                                <div v-else-if="scope.row.name == '客服经理'">
                                    <span>{{scope.row.serviceManagerTel}}</span>
                                </div>
                                <div v-else-if="scope.row.name == '客服组长'">
                                    <span>{{scope.row.teamLeaderTel}}</span>
                                </div>
                                <div v-else-if="scope.row.name == '质检人员'">
                                    <span>{{scope.row.qualityInspectionTel}}</span>
                                </div>
                                <div v-else-if="scope.row.name == '官方热线'">
                                    <span>{{scope.row.officialPhone}}</span>
                                </div>
                                <div v-else>--</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item>
                    <div class="service-table">
                        <div class="tip-text">{{modelName}}外包订单详情：</div>
                        <el-table class="common-table" border :data="orderList">
                            <el-table-column prop="orderNo" label="订单编号" :formatter="tableColumn"></el-table-column>
                            <el-table-column prop="createTime" label="订单创建时间" :formatter="tableColumn"></el-table-column>
                            <el-table-column prop="serviceTime" label="订单服务时间" :formatter="tableColumn"></el-table-column>
                            <el-table-column prop="shopNum" label="约定服务店铺数" :formatter="tableColumn"></el-table-column>
                            <el-table-column prop="dayNum" label="协议单账号日均咨询量" :formatter="tableColumn"></el-table-column>
                            <el-table-column prop="dayServed" label="已服务天数" :formatter="tableColumn"></el-table-column>
                            <el-table-column prop="remaininDay" label="剩余服务天数" :formatter="tableColumn"></el-table-column>
                        </el-table>
                    </div>
                </el-form-item>
                <el-form-item>
                    
                    <div class="service-table">
                        <div class="tip-text">附件为{{DateTransCN(date)}}{{modelName}}工作汇报表格，以下是店铺{{DateTransCN(date)}}的服务数据请查收：</div>
                        <div class="tip-text">{{DateTransCN(date)}}配置派遣客服人数</div>
                        <el-table class="common-table" border :span-method="objectSpanMethodShop" :data="ruleForm.shopList">
                            <el-table-column label="平台" prop="platformName" :formatter="tableColumn"></el-table-column>
                            <el-table-column label="店铺" prop="shopName" :formatter="tableColumn"></el-table-column>
                            <el-table-column label="服务客服数量" :formatter="tableColumn" prop="number"></el-table-column>
                            <el-table-column class-name="bg-col" label="派遣服务客服总数" :formatter="tableColumn" prop="serviceNumber"></el-table-column>
                        </el-table>
                    </div>
                </el-form-item>
                <el-form-item>
                    <div class="service-table">
                        <div class="tip-text">{{DateTransCN(date)}}店铺服务数据确认</div>
                        <el-table class="common-table" style="margin-bottom: 0" :span-method="objectSpanMethod" border :data="serviceKpi">
                            <el-table-column prop="departmentName" label="部门" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="shopName" label="店铺名称" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="loginPlatform" label="平台" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="shopConsultationNum" class-name="bg-col" label="总接待量" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="frequency" label="班次" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="loginAccount" label="子账号" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="consultationNum" label="子账号咨询量" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="firsrTime" label="首次响应时间" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="avgTime" label="平均响应" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="qaRate" label="问答比" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="conversionRate" label="转化率" :formatter="tableColumn2"></el-table-column>
                            <el-table-column prop="replyRate" label="回复率" :formatter="tableColumn2"></el-table-column>
                        </el-table>
                        <div class="tip-text-red">"/"为当前三圆无权限/无官方途径查询服务数据、当天无接待量导致无数或者暂时未开账号</div>
                    </div>
                </el-form-item>
                <el-form-item>
                    <div class="tip-text">{{DateTransCN(date)}}质检异常记录</div>
                    <el-table class="common-table" style="margin-bottom: 0" border :data="hisNotList">
                        <el-table-column prop="platformName" label="聊天日期" :formatter="tableColumn">
                            <template slot-scope="">
                                <span>{{DateTransCN(date)}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="qualityTime" label="质检日期">
                            <template slot-scope="scope">
                                <span>{{formatDateTimer(scope.row.qualityTime)}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="qualityName" label="质检人员" :formatter="tableColumn"></el-table-column>
                        <el-table-column prop="shopName" label="店铺名称" :formatter="tableColumn"></el-table-column>
                        <el-table-column prop="serviceInspected" label="客服姓名" :formatter="tableColumn"></el-table-column>
                        <el-table-column prop="subaccountName" label="店铺子账号" :formatter="tableColumn"></el-table-column>
                        <el-table-column prop="customerAccount" label="顾客账号" :formatter="tableColumn"></el-table-column>
                        <el-table-column prop="classificationQuality.bigList" label="客服问题大类" show-overflow-tooltip width="120"></el-table-column>
                        <el-table-column prop="classificationQuality.pointList" label="客服问题点" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="classificationQuality.descList" label="问题点详细" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="amount" label="扣款" :formatter="tableColumn"></el-table-column>
                        <el-table-column prop="pointDeduction" label="扣分值" :formatter="tableColumn"></el-table-column>
                        <el-table-column prop="score" label="分值" :formatter="tableColumn"></el-table-column>
                    </el-table>
                    <div class="tip-text-red">*完整质检记录将以附件形式随邮件发送。</div>
                </el-form-item>
                <el-form-item>
                    <div class="content-text" v-html="ruleForm.conte"></div>
                </el-form-item>
                <el-form-item>
                    <div class="content-text">{{DateTransCN(date)}}</div>
                </el-form-item>
            </el-form>
        </div>
        <div class="grey-line"></div>
        <div class="sendEmailTwo-bottom">
            <el-button class="common-screen-btn" plain @click="() => { $router.push(`/mail/setting`) }">取消发送</el-button>
            <div>
                <el-button class="common-screen-btn" plain @click="() => { $router.push(`/mail/setting/sendEmailTwo?modelId=${this.modelId}&date=${this.date}&modelName=${modelName}`) }">上一步：服务数据预览</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => { handleDown() }">确认并发送</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import {downloadImg} from "../../utils/htmlToImg.js"
import { tableColumn, duplicateRemoval, tableColumn2, formatDateTimer, DateTransCN, Config } from "../../utils/index.js"
import { emailSerKPI, custShopList, emailDepartList, qualityHisNot, emailModDetail, customerSelect, serviceDis, emailImgUpload, sendEmail } from "../../service/dataInfo.js"
import { upload } from "../../service/upload.js"
export default {
    data() {
        return {
            tableColumn,
            DateTransCN,
            tableColumn2,
            formatDateTimer,
            loading: false,
            date: this.$route.query.date,
            modelId: this.$route.query.modelId,
            modelName: this.$route.query.modelName,
            companyId: this.$sto.get(Config.constants.userInfo).company_id,
            ruleForm: {},
            rules: { // 校验
                date: [{ required: true, message: "请选择日期", trigger: "change" }],
            },
            serviceKpi: [], // 服务数据概览
            shopIdsArr: [], // 获取店铺
            hisNotList: [], // 异常质检记录列表
            uploadTable: [], // 店铺服务数据上传列表
            orderList: []
        }
    },
    mounted () {
        this.custShopList().then(() => {
            let shopIds = this.shopIdsArr
            this.emailSerKPI(shopIds)
            this.qualityHisNot(shopIds)
            this.emailModDetail(this.modelId).then(() => {
                this.serviceDis()
            })
        })
    },
    methods: {
        handleDown () { // 导出pdf
            // htmlToImg.downloadImg(document.querySelector('#dowmloadImg'), '服务数据')
            downloadImg(document.querySelector('#dowmloadImg'), `服务数据-${this.date}`, async (file) => {
                console.log(file)
                this.loading = true
                let { code, data } = await upload({ file: file })
                if ( code == 200 ) {
                    let params = {
                        emailModelId: this.modelId,
                        imgUrl: data.filename
                    }
                    let res = await emailImgUpload(params)
                    if (res.code == 200) {
                        let sendRes = await sendEmail({ emailModelId: this.modelId })
                        if (sendRes.code == 200) {
                            this.$message.success("邮件已发送")
                            this.$router.push('/mail/setting')
                        }
                    }
                } else {
                    this.$message.error("上传失败")
                }
                this.loading = false
            })
        },
        async serviceDis () { // 客服分配设置详情
            let { data } = await serviceDis({ emailModelId: this.modelId })
            if (data && data.length) {
                let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
                let serviceNumber = ''
                ruleForm.shopList.map((item, index, arr) => {
                    if (index == 0) {
                        item.spanRow = data.length
                    }
                    data.map((itemTwo) => {
                        if (item.shopId == itemTwo.shopId) {
                            item.number = itemTwo.number
                            item.serviceNumber = itemTwo.serviceNumber
                            item.spanRow = arr.length
                            serviceNumber = itemTwo.serviceNumber
                        }
                    })
                })
                ruleForm.number = serviceNumber
                this.ruleForm = ruleForm
            }
        },
        changeCust (val) {
            this.orderList = this.custList.filter((item) => { return item.id == val })
        },
        async remoteMethod (val) {
            let { data } = await customerSelect({ nickName: val, companyId: this.companyId })
            let newArr = []
            if (data && data.length) {
                data.map((item) => {
                    newArr.push({id: item.id, nickName: item.nickName})
                })
            } 
            newArr = duplicateRemoval(newArr, 'id')
            this.custSelect = newArr || []
            this.custList = data || []
        },
        async emailModDetail(modelId) {
            this.loading = true
            let { data } = await emailModDetail({id: modelId})
            this.loading = false
            if (data) {
                if (data.customerName) {
                    this.remoteMethod(data.customerName).then(() => {
                        this.changeCust(data.customerId)
                    })
                }
                let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
                ruleForm.infoSetList = [ // 对接信息设置
                        {name: '项目负责人', projectLeaderName: data.projectLeaderName || '', projectLeaderTel: data.projectLeaderTel || ''},
                        {name: '客服经理', serviceManagerName: data.serviceManagerName || '', serviceManagerTel: data.serviceManagerTel || ''},
                        {name: '客服组长', teamLeaderName: data.teamLeaderName || '', teamLeaderTel: data.teamLeaderTel || ''},
                        {name: '质检人员', qualityInspectionName: data.qualityInspectionName || '', qualityInspectionTel: data.qualityInspectionTel || ''},
                        {name: '对接群', grop: data.grop || ''},
                        {name: '官方热线', officialPhone: data.officialPhone || ''},
                    ]
                ruleForm.conte = data.conte
                this.ruleForm = ruleForm
            }
        },
        async qualityHisNot (shopIds) { // 质检记录异常列表
            let params = {
                emailModelId: this.modelId,
                startDate: this.date,
                adopt: '不通过',
                shopIds: shopIds
            }
            let { data } = await qualityHisNot(params)
            let hisNotList = []
            if (data && data.length) {
                data.map((item) => {
                    if (item.qualityHisNot && item.qualityHisNot.length) {
                        item.qualityHisNot.map((itemTwo) => {
                            hisNotList.push(itemTwo)
                        })
                    }
                })
            }
            this.hisNotList = hisNotList
            console.log(hisNotList)
        },
        objectSpanMethodShop({ row, columnIndex }) { // 合并表格
            if (columnIndex == 3) {
                if (row.spanRow) {
                    return {
                        rowspan: row.spanRow,
                        colspan: 1
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                }
            }
        },
        objectSpanMethod({ row, columnIndex }) { // 合并表格
            if (columnIndex == 0 || columnIndex == 1 || columnIndex == 3) {
                if (row.spanRow || row.spanRowShop) {
                    return {
                        rowspan: columnIndex == 0 ? row.spanRow : row.spanRowShop,
                        colspan: 1
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                }
            }
        },
        async custShopList() { // 获取店铺信息 
            let { data } = await custShopList({ id: this.modelId })
            let shopIds = []
            if (data && data.length) {
                data.map((item) => {
                    shopIds.push(item.shopId)
                    item.fileName = ''
                })
            }
            this.uploadTable = data
            this.shopIdsArr = shopIds
            this.ruleForm.shopList = data
            // this.emailSerKPI(shopIds)
        },
        async emailSerKPI (shopIds) { // 服务数据
            let params = {
                startDate: this.date,
                emailModelId: this.modelId,
                shopIds: shopIds
            }
            let { data } = await emailSerKPI(params)
            let departList = (await emailDepartList(params)).data || []
            let serviceKpi = []
            let serviceKpiNull = []
            departList.map((item) => {
                if (item.shopIds) {
                    let shopIdsArr = item.shopIds.split(',')
                    shopIdsArr.map((itemTwo) => {
                        data.map((itemThree, indexThree) => {
                            if (itemThree.account && itemThree.account.length) {
                                itemThree.account.map((itemfour, indexFour) => {
                                    if (itemTwo == itemfour.shopId) {
                                        serviceKpi.push( Object.assign({ ...item }, { ...itemfour, shopConsultationNum: itemThree.shopConsultationNum, spanRowShop: indexFour == 0 ? itemThree.account.length : 0 }) )
                                    } else {
                                        if (data.length != (indexThree + 1)) {
                                            serviceKpiNull.push( { ...itemfour, shopConsultationNum: itemThree.shopConsultationNum, departmentName: "/", spanRowShop: indexFour == 0 ? itemThree.account.length : 0 } )
                                        }
                                    }
                                })
                            }
                            if (data.length == (indexThree + 1)) {
                                serviceKpiNull.push( { departmentName: 'TTL', shopConsultationNum: itemThree.allNum, spanRow: 1, spanRowShop: 1 } )
                            }
                        })
                    })
                }
            })
            serviceKpi = serviceKpi.concat(serviceKpiNull)
            serviceKpi = duplicateRemoval(serviceKpi, 'id')
            serviceKpi.reverse()
            let spanRowNull = 0
            let numNull = 0
            serviceKpi.map((item, index) => {
                if (item.departmentName == '/') {
                    spanRowNull++
                    numNull = index
                }
            })
            serviceKpi[numNull].spanRow = spanRowNull
            departList.map((item) => {
                let spanRow = 0
                let num = 0
                serviceKpi.map((itemTwo, indexTwo) => {
                    if (item.departmentName == itemTwo.departmentName) {
                        spanRow++
                        num = indexTwo
                    }
                })
                serviceKpi[num].spanRow = spanRow
            })
            serviceKpi.reverse()
            this.serviceKpi = serviceKpi
        }
    }
}
</script>
<style lang="less" scoped>
.sendEmailTwo-container {
    text-align: left;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    #dowmloadImg {
        padding: 16px;
    }
    .content-text {
        font-size: 18px;
        margin-top: 18px;
    }
    .common-table {
        margin: 18px 0 18px;
        width: 98%;
    }
    /deep/.el-button--text {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .uploadBtn-container {
        height: 32px;
        line-height: 32px;
        width: 88px;
        text-align: center;
        color: #1890ff;
        border: 1px solid #1890ff;
        border-radius: 4px;
        position: relative;
        .upload-input {
            position: absolute;
            width: 100%;
            height: 100%;
            cursor: pointer;
            left: 0;
            top: 0;
            opacity: 0;
            // font-size: 0;
        }
    }
    .service-table {
        /deep/.bg-col {
            background: #DCEEFF;
        }
        /deep/.el-table--border td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
            border-color: #5fbfff !important;
        }
        /deep/.el-table td, .el-table th.is-leaf {
            border-color: #5fbfff !important;
        }
        /deep/.el-table--border th {
            border-color: #5fbfff !important;
        }
        .common-table {
            border-color: #5fbfff !important;
        }
    }
    /deep/.el-table--border th {
        background: #f2f4f5;
        padding: 0;
    }
    .tip-text {
        font-size: 18px;
        color: #101010;
        line-height: 18px;
        margin-bottom: 12px;
    }
    .tip-text-red {
        font-size: 14px;
        color: #e83636;
        line-height: 28px;
    }
    .add-btn {
        height: 38px;
        line-height: 38px;
        border: 1px solid #1890ff;
        padding: 0 8px 0 8px;
        color: #1890ff;
        display: inline-block;
        border-radius: 4px;
        margin-bottom: 16px;
        cursor: pointer;
        position: relative;
        &::after {
            display: block;
            content: '若数据有误或缺失，请在店铺服务分析中上传数据。';
            white-space: nowrap;
            position: absolute;
            left: 110%;
            top: 0;
            bottom: 0;
            margin: auto;
            color: #999;
            font-size: 12px;
        }
    }
    .form-container {
        width: 100%;
        margin: 24px 0;
    }
    .form-input {
        width: 400px;
    }
    .sendEmailTwo-main {
        flex: 1;
        overflow: auto;
        overflow-x: hidden;
    }
    .sendEmailTwo-bottom {
        height: 64px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 16px;
    }
    .step-container {
        height: 117px;
        display: flex;
        align-items: center;
        /deep/.el-step__title.is-process {
            color: #C0C4CC;
            font-weight: 500 !important;
        }
        /deep/.el-step__icon.is-text {
            color: #C0C4CC;
            border-color: #C0C4CC;
        }
        /deep/.el-step__head.is-finish .is-text {
            color: #409EFF !important;
            border-color: #409EFF !important;
        }
    }
    .main-title {
        box-sizing: border-box;
        padding: 16px;
        height: 68px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        span {
            color: #101010;
            font-size: 18px;
        }
        &::after {
            display: flex;
            content: ' ';
            position: absolute;
            height: 1px;
            width: 120%;
            background: #f2f4f5;
            bottom: 0;
            left: -10%;
        }
    }
    .title {
        box-sizing: border-box;
        padding: 6px 16px 22px;
        height: 56px;
        color: #333;
        font-size: 24px;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
        position: relative;
        &::after {
            display: flex;
            content: ' ';
            position: absolute;
            height: 1px;
            width: 120%;
            background: #f2f4f5;
            bottom: 0;
            left: -10%;
        }
    }
    .grey-line {
        height: 16px;
        width: 120%;
        margin: 0 0 0 -10%;
        background: #eee;
    }
}
</style>